import { css, Interpolation, ThemeProps, DefaultTheme } from 'styled-components';

const campaignHeading = (): Interpolation<ThemeProps<DefaultTheme>> => css`
    ${({ theme }) => {
        return css`
            font-family: ${theme.fonts.secondary};
            font-size: 1.75rem;
            font-style: italic;
            line-height: 1.2;
            text-transform: uppercase;
        `;
    }}
`;

export default campaignHeading;
