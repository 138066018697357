import styled, { css } from 'styled-components';

import type { ResultsDisplay } from './Course';

import { Container as CampaignCourseSearchContainer } from 'Campaigns/Recruitment/CourseSearch/CourseSearch.styled';

import BaseInputSearch from '../Search';
import { Input } from '../Search.styled';

export const Container = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const InputSearch = styled(BaseInputSearch)`
    ${({ theme }) => css`
        border-radius: 0.75rem;

        ${Input} {
            font-size: 1rem;
            line-height: normal;

            ::placeholder {
                font-size: 1.25rem;

                @media ${theme.breakpoints.medium.media} {
                    font-size: 1.3rem;
                }
            }

            @media ${theme.breakpoints.xlarge.media} {
                font-size: 1.4rem;
            }

            ${CampaignCourseSearchContainer} & {
                border: none;
            }
        }
    `}
`;

export const AutoComplete = styled.ul<{ $displayType: ResultsDisplay }>`
    ${({ theme, $displayType }) => css`
        max-height: 20rem;
        overflow: auto;

        ${$displayType === 'dropdown' &&
        css`
            display: block;
            list-style: none;
            position: absolute;
            top: calc(100% + 0.4rem);
            width: 100%;
            background: ${theme.colors.interactiveContrast.hex};
            box-shadow: 0 0.4rem 0.4rem 0.1rem rgba(0, 0, 0, 0.2);
            border-radius: 0.75rem;
            padding: 1rem 1.25rem;
            z-index: 10;
            margin: 0;

            @media ${theme.breakpoints.medium.media} {
                padding: 1.5rem 1.25rem;
            }
        `}

        ${$displayType === 'list' &&
        css`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 2rem 0 0;
            padding: 0;

            ${Result} {
                width: 100%;
                color: ${theme.colors.primary.hex};

                & > a {
                    font-size: 1.2rem;
                }

                @media ${theme.breakpoints.medium.media} {
                    width: 47.5%;
                }
            }
        `}
    `}
`;

export const Result = styled.li`
    display: block;

    &:not(:last-child) {
        margin-bottom: 0.75rem;
    }
`;
