import styled, { css } from 'styled-components';
import campaignHeading from 'Common/styles/campaignHeading';

export const Container = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.accents[2].hex};
        color: ${theme.colors.primaryContrast.hex};
        border-radius: 0.5rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        height: 100%;

        @media ${theme.breakpoints.xlarge.media} {
            flex-direction: row;
            align-items: center;
            gap: 2rem;
        }
    `}
`;

export const Inner = styled.div`
    flex: 0 0 40%;
`;

export const Heading = styled.h2`
    ${campaignHeading()};
    margin: 0;
`;

export const Text = styled.p`
    margin: 0.25rem 0 0;
    max-width: 14rem;
`;
